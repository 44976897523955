.partner-header {
  margin-top: 65px;
  margin-bottom: 25px;
  text-align: center;
}

.partner-header h1 {
  margin-bottom: 25px;
}

.partner-header p {
  font-size: 20px;
}

.partner-line {
  margin-top: 85px;
  height: 2px;
  background-color: var(--color-border);
}

.partner-fields {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

.partner-input {
  margin-bottom: 25px;
}

.partner-columns {
  display: flex;
}

.partner-column-left {
  flex: 1;
  padding-right: 25px;
}

.partner-column-right {
  flex: 0 0 265px;
}

.partner-column-left-upload {
  flex: 0 0 50%;
  padding-right: 10px;
}

.partner-column-right-upload {
  flex: 0 0 50%;
  padding-left: 10px;
}

.partner-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 70px;
}

.partner-link {
  font-size: 24px;
  font-weight: 400;
  color: var(--color-secundary);
}

.partner-button {
  font-size: 24px;
  font-weight: 400;
  color: var(--color-secundary);
}

.partner-button:disabled {
  cursor: not-allowed;
}

.partner-success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 90px);
}

.partner-success-info {
  text-align: center;
}

.partner-success-info h1 {
  margin-top: 45px;
  margin-bottom: 15px;
}

.partner-success-info p {
  font-size: 28px;
}

.partner-radios {
  display: flex;
  margin-bottom: 35px;
}

.partner-radios-left {
  flex: 0 0 50%;
  padding-right: 10px;
}

.partner-radios-right {
  flex: 0 0 50%;
  padding-left: 10px;
}

.partner-radios-field {
  cursor: pointer;
  display: block;
  padding: 14px 24px;
  background-color: #fff;
  border: 1px solid var(--color-border);
}

@media (max-width: 840px) {
  .partner-body {
    padding-bottom: 20px;
  }

  .partner-header {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .partner-header h1 {
    margin-bottom: inherit;
  }

  .partner-line {
    margin-top: 20px;
  }

  .partner-columns {
    flex-direction: column;
  }

  .partner-column-left {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .partner-column-right {
    flex: 1;
  }

  .partner-column-left-upload {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .partner-column-right-upload {
    flex: 1;
    padding-left: 0;
  }

  .partner-input {
    margin-bottom: 20px;
  }

  .partner-actions {
    padding-bottom: 20px;
  }

  .partner-radios {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .partner-radios-left {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .partner-radios-right {
    flex: 1;
    padding-left: 0;
  }
}
