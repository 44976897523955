.textarea {
  position: relative;
  height: 160px;
  background-color: #fff;
  border: 1px solid var(--color-border);;
  border-radius: 3px;
  color: var(--color-text-2);
}

.textarea label {
  position: absolute;
  left: 20px;
  right: 20px;
  font-size: 14px;
  padding-top: 7px;
  color: inherit;
  cursor: text;
  background-color: #fff;
}

.textarea label + textarea {
  padding-top: 30px !important;
  padding-bottom: 37px !important;
}

.textarea textarea {
  resize: none;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  font-size: 20px;
  line-height: 1.2;
  color: inherit;
  padding: 8px 20px;
}

.textarea textarea:focus {
  outline: none;
}

.textarea ::placeholder {
  color: #a6a6a6;
}

.textarea-max-length {
  position: absolute;
  right: 15px;
  bottom: 0;
  left: 0;
  padding-bottom: 15px;
  padding-right: 15px;
  background-color: #fff;
  font-size: 14px;
  color: var(--color-secundary);
  text-align: right;
  cursor: text;
}
