.input {
  position: relative;
  height: 68px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: var(--color-text-2);
}

.input.border {
  border-color: var(--color-border);
}

.input label {
  position: absolute;
  top: 7px;
  left: 20px;
  font-size: 14px;
  color: inherit;
  cursor: text;
}

.input label + input {
  padding-top: 30px !important;
}

.input input {
  appearance: none;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  font-size: 20px;
  color: inherit;
  padding: 8px 20px;
}

.input input:focus {
  outline: none;
}

.input ::placeholder {
  color: #a6a6a6;
}
