.adm-page {
  padding-top: 60px;
  padding-bottom: 60px;
}

.adm-page-header {
  padding-bottom: 80px;
  border-bottom: 2px solid var(--color-border);
}

.adm-page-header h1 {
  margin-bottom: 65px;
  text-align: center;
}

.adm-page-body {
  padding-top: 60px;
}

@media (max-width: 840px) {
  .adm-page {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .adm-page-header {
    padding-bottom: 20px;
  }

  .adm-page-header h1 {
    margin-bottom: 20px;
  }

  .adm-page-body {
    padding-top: 20px;
  }
}
