.cancel-partnership-content {
  padding-top: 84px;
  padding-bottom: 84px;
}

.cancel-partnership-box {
  max-width: 975px;
  margin-left: auto;
  margin-right: auto;
  padding: 84px;
  border-radius: 10px;
  background-color: #fff;
}

.cancel-partnership-image {
  text-align: center;
}

.cancel-partnership-text {
  max-width: 600px;
  margin: 80px auto;
  text-align: center;
}

.cancel-partnership-text h1 {
  margin-bottom: 46px;
  line-height: 1.4;
  font-size: 32px;
  font-weight: 700;
}

.cancel-partnership-text p {
  line-height: 1.4;
  font-size: 28px;
}

.cancel-partnership-actions {
  display: flex;
  justify-content: center;
}

.cancel-partnership-actions button {
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 840px) {
  .cancel-partnership-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cancel-partnership-box {
    padding: 20px;
  }

  .cancel-partnership-text {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .cancel-partnership-text h1 {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .cancel-partnership-text p {
    font-size: 16px;
  }

  .cancel-partnership-actions button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 520px) {
  .cancel-partnership-actions {
    flex-wrap: wrap;
  }

  .cancel-partnership-actions button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .cancel-partnership-actions button:first-child {
    margin-bottom: 10px;
  }
}