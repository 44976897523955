.loading-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.loading-donut {
  --size: 35px;
  --border-color: #343434;
  display: inline-block;
  border: 2px solid transparent;
  border-left-color: var(--border-color);
  border-top-color: var(--border-color);
  border-bottom-color: var(--border-color);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  animation: donut-spin 0.7s linear infinite;
}

.loading-donut.small {
  --size: 22px;
  width: var(--size);
  height: var(--size);
  border-width: 2px;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 840px) {
  .loading-center {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
