.voucher-form-container {
  padding: 40px 50px;
}

.voucher-form-header {
  padding-bottom: 20px;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
  border-bottom: 2px solid var(--color-border);
}

.voucher-form-input {
  margin-bottom: 15px;
}

.voucher-form-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.voucher-form-radio-group li {
  margin-right: 40px;
}

.voucher-form-radio-group li:last-child {
  margin-right: 0;
}

.voucher-form-images {
  margin-bottom: 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
}

.voucher-form-images-label {
  margin-top: 5px;
  display: block;
  font-size: 12px;
  color: rgba(0,0,0,0.4);
  text-align: center;
}

.voucher-form-columns {
  display: flex;
}

.voucher-form-column-left {
  width: 50%;
  padding-right: 7.5px;
}

.voucher-form-column-right {
  width: 50%;
  padding-left: 7.5px;
}

.voucher-form-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.voucher-form-actions button {
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 840px) {
  .voucher-form-container {
    padding: 20px;
  }

  .voucher-form-header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 22px;
  }

  .voucher-form-images {
    margin-bottom: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 620px) {
  .voucher-form-radio-group li {
    margin-right: 10px;
  }

  .voucher-form-columns {
    flex-direction: column;
  }

  .voucher-form-column-left {
    width: 100%;
    padding-right: 0;
  }

  .voucher-form-column-right {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }

  .voucher-form-actions {
    margin-top: 20px;
  }

  .voucher-form-actions {
    flex-direction: column;
  }

  .voucher-form-actions button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .voucher-form-actions button:first-child {
    order: 1;
    margin-top: 10px;
  }
}
