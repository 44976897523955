.confirmation-modal {
  padding: 40px;
}

.confirmation-modal-header {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.confirmation-modal-body {
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
}

.confirmation-modal-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal-actions button {
  margin-left: 15px;
  margin-right: 15px;
}

@media (max-width: 840px) {
  .confirmation-modal {
    padding: 20px;
  }

  .confirmation-modal-header {
    margin-bottom: 20px;
    font-size: 20px;
    padding-bottom: 10px;
  }

  .confirmation-modal-body {
    margin-bottom: 20px;
  }
}

@media (max-width: 460px) {
  .confirmation-modal-actions {
    flex-direction: column;
  }

  .confirmation-modal-actions button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .confirmation-modal-actions button:first-child {
    order: 1;
    margin-top: 10px;
  }
}
