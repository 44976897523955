.addresses-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.addresses-header-info h1 {
  margin-bottom: 20px;
}

.addresses-header-info p {
  font-size: 20px;
}

.addresses-body {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 840px) {
  .addresses-header {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .addresses-header-info {
    margin-bottom: 20px;
  }

  .addresses-header-info h1 {
    margin-bottom: 10px;
  }

  .addresses-body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
