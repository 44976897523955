.footer {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--color-secundary);
  color: #fff;
}

.footer-links {
  display: flex;
  margin-bottom: 38px;
}

.footer-links li {
  margin-right: 25px;
}

.footer-links li:last-child {
  margin-right: 0;
  margin-left: 140px;
}

.footer-links li a {
  display: block;
  color: #fff;
}

.footer-links li a:hover {
  text-decoration: underline;
}

@media (max-width: 840px) {
  .footer {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .footer-links {
    display: block;
    margin-bottom: 20px;
  }

  .footer-links li {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0;
  }

  .footer-links li:last-child {
    margin-left: 0;
  }
}
