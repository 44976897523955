:root {
  --color-text-1: #343434;
  --color-text-2: #343434;
  --color-primary: #c5cb71;
  --color-secundary: #343434;
  --color-border: #d9d9d9;

  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  line-height: 1.6;
}

body, h1, h2, h3, h4, h5, h6,
p, hr, ul {
  margin: 0;
  padding: 0;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

button {
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

button:focus {
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="checkbox"],
input[type="radio"] {
  position: relative;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  pointer-events: none;
}

input[type="checkbox"]:disabled + label {
  cursor: not-allowed;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  color: var(--color-text-2);
}

input[type="checkbox"] + label button,
input[type="radio"] + label button {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
}

input[type="checkbox"] + label button:hover,
input[type="radio"] + label button:hover {
  text-decoration: underline;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #fff;
  border: 2px solid var(--color-secundary);
  margin-right: 10px;
  position: relative;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: center center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEHElEQVR4Xu2aSagVRxSGPydUEudZkWycZ40xaly8EHAlgggKgTiLioqKCoq4EYmCJk4kASFBIsbgQhBxHqJx4d6lMQ7RZJ+ds/JD1bNt+91bXdX33u57b+8et051ff/563TX6deOBr/aNTg/TQGaDmhwBZpboMEN0BBFsCPwsq1E1/MWGAacAUYBvwCrgVdxIepVgOHAH8CQCPBJ4Ju4CPUowAgDPzjB9ieARcBr+1u9CVAK3jIfB5ZYEepJgJEm84Mcnmy/AkslQr0IoEJ3HXCBt/ocA5bXgwCjDfxAh8zHh3xXdAEEr2o/wANeIf8XWYAxBr6/J7zCbhdVgLHG9iHw/wAtRRRgHHANCIYHHhRNgPEGvl+A7R8BXwpecxRJAMHrUdc3K/giCTDBZD4UvgV4GBWwCA6YaOD7BGb+A/giOGAScBWoCHw5AaYBawAVjb3A04AM+IRONvC9fYJNjNaemPlyp8F5wG9AFzPwMjAXeBawmDShU4ArQEXh23KAOic/AO1jKz4PSJjnaUg8xgpetu/lEWtDVOiUeTmg5BUvgruAnSUizgLzgRflJvb8/VOT+arARx3QAfgJWOmw8NPAwlKNRoc5koZMNfA9PeMV5pz5aA3oCvxu9rjrvU8BXyc1GV0niI37DFCdCYHXm53e8MraPnpvbQFlVHs77aUiqSZja38t7QRmvJ42gu/hGa8wL3i7BbSf1Tv3uVpbSz7BwOfApQzgVfB0ukt9yQFawOzUke8C1HNfAbxJOYfglfnuKeOiw5V5b3jrAL1l3QB0zPS9jpoPD64iTDfCh8DfN3veK/MW1D4G1U/7E9AHBd/rR2CtQ/AMA9/NYWxbQzKBtw6wNxkK3AI+CVjYIWBjifiZwEUgF/BxAfS3vqfJCWnay3He/cDWBBG+AC5kAK89/zggSe+FJh2H1W+7GXgC0+Fpe+ROswz8xwELl+0zhU9ygF2f3sfVfQl5NuvN8mdAp7oDQAj836bgZZb5eBFMSowsq0fkRwFZyyK0YvClHGAX/hVwDuicBYnHHBWFdxFAY+aY1+VOHgAhIYLXnn8SMkm5WNee4AJA/2AQ7xGUm9/396rAuzrAQuhzsoqaq2i+8PdMwato5l2KYBLAeuCwL5lDXFXh0zrArn8bsMcBJu0QwWvP/5s2MGS8r513AztCbhyLrQm8rwPs2vcBWzIQoWbwoQIoPlSEv0zBq6rto0nz3QLROXT42ezhhJrDZ+EAy/09sCmFCIJXwfsvRUxFhmbhALuwg8AGh1XmBj5LB1juI8C6EiLcNXu+5pn3fRFySDBqjemjavzKHXwlHGDnVC9gVUSBXMJXSgA777fAMuAOsDgPBS/JvlkWQZftkbsxTQFyl5IqL6jpgCoLnrvbNR2Qu5RUeUEN74C3X6mzptvS5S0AAAAASUVORK5CYII=');
}

body {
  overflow-x: hidden;
  background-color: #f7f7fa;
  font-family: var(--font-family);
  color: var(--color-text-2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

img[width],
img[height] {
  max-width: none;
}

/* utils */

.text-underline {
  text-decoration: underline;
}

/* container */

.container {
  max-width: 1560px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* title */

.title {
  font-size: 32px;
  font-weight: 700;
  color: var(--color-text-2);
}

@media (max-width: 840px) {
  .title {
    font-size: 22px;
  }
}

/* tab-filter */

.tab-filter {
  position: relative;
  display: flex;
}

.tab-filter::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--color-border);
  content: '';
}

.tab-filter li {
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding: 12px 18px;
  border-bottom: 2px solid var(--color-border);
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

.tab-filter li.active,
.tab-filter li:hover {
  color: var(--color-text-1);
  border-bottom-color: var(--color-primary);
}

/* table */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
}

.table thead tr {
  background-color: #fff;
}

.table thead tr th {
  padding: 10px 26px;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}

.table tbody tr td {
  padding: 10px 26px;
  font-size: 15px;
}

.table tbody tr:nth-child(even) {
  background-color: #fff;
}

.table-actions {
  width: 100px;
}

.table-actions-container {
  display: flex;
  align-items: center;
}

.table-actions-container button {
  margin-right: 35px;
}

.table-actions-container button:last-child {
  margin-right: 0;
}

.table-status {
  color: #790404;
}

.table-status.active {
  color: #166649;
}

@media (max-width: 840px) {
  .table-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* form-reset-password */

.reset {
  max-width: 720px;
  margin-top: 180px;
  margin-left: auto;
  margin-right: auto;
  padding: 62px 78px;
  border-radius: 20px;
  background-color: #fff;
}

.reset-info {
  text-align: center;
}

.reset-form {
  margin-top: 35px;
}

.reset-info h1 {
  line-height: 1.2;
  margin-top: 35px;
  margin-bottom: 18px;
}

.reset-info p {
  font-size: 20px;
}

.reset-info p .link {
  cursor: pointer;
  color: #343434;
}

.reset-info p .link:hover {
  text-decoration: underline;
}

.reset-info-actions {
  margin-top: 40px;
}

.reset-info button {
  width: 100%;
}

.reset-input {
  margin-bottom: 18px;
}

.reset-actions {
  margin-top: 35px;
  text-align: center;
}

.reset-actions button {
  width: 100%;
}

.reset-actions a {
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #343434;
  text-align: center;
}

.reset-actions a:hover {
  text-decoration: underline;
}

@media (max-width: 840px) {
  .reset {
    margin-top: 20px;
    padding: 20px;
  }

  .reset-form {
    margin-top: 20px;
  }

  .reset-info h1 {
    margin-top: 20px;
  }

  .reset-info-actions {
    margin-top: 20px;
  }

  .reset-actions {
    margin-top: 20px;
  }
}
