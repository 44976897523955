.login {
  max-width: 560px;
  padding-top: 180px;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.login-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-info h1 {
  margin-bottom: 50px;
}

.login-icon {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 85px;
}

.login-input {
  margin-bottom: 50px;
}

.login-actions {
  text-align: center;
}

.login-actions button {
  width: 100%;
}

.login-actions a {
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #343434;
  text-align: center;
}

.login-actions a:hover {
  text-decoration: underline;
}

@media (max-width: 840px) {
  .login {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .login-info h1,
  .login-input {
    margin-bottom: 20px;
  }
}
