.modal-container,
.modal-container-small {
  position: relative;
  outline: 0;
  width: 990px;
  max-height: 95vh;
  overflow: auto;
  border-radius: 20px;
  background-color: #fff;
}

.modal-container-small {
  width: 600px;
}

.modal-overlay {
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-close {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 50px;
  width: 20px;
}

@media (max-width: 840px) {
  .modal-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    border-radius: 0;
    max-height: initial;
  }
}