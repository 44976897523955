.button-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  height: 48px;
  padding: 0 20px;
  border-radius: 5px;
  background-color: var(--color-primary);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: 700;
  color: #343434;
}
