.profile-header h1 {
  margin-bottom: 22px;
}

.profile-body {
  display: flex;
}

.profile-image {
  width: 35%;
}

.profile-form {
  width: 65%;
  padding-left: 65px;
}

.profile-form-input {
  margin-bottom: 25px;
}

.profile-form-actions {
  display: flex;
  justify-content: flex-end;
}

.profile-form-info {
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 16px;
  color: #b8b8c9;
}

@media (max-width: 840px) {
  .profile-body {
    flex-direction: column;
  }

  .profile-image,
  .profile-form {
    width: 100%;
  }

  .profile-form {
    padding-left: 0;
    margin-top: 20px;
  }

  .profile-form-input {
    margin-bottom: 20px;
  }

  .profile-form-actions button {
    width: 100%;
  }
}
