.partner-list-page {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #fff;
}

.partner-list-page-header {
  margin-bottom: 40px;
}

.partner-list-page-header h1 {
  margin-bottom: 20px;
  font-size: 50px;
}

.partner-list-page-header p {
  font-size: 20px;
  color: var(--color-text-1);
}

.partner-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
}

.partner-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.66%;
  padding-right: 10px;
  margin-bottom: 40px;
}

@media (max-width: 1110px) {
  .partner-list li {
    width: 20%;
  }
}

@media (max-width: 840px) {
  .partner-list li {
    width: 25%;
  }
}

@media (max-width: 640px) {
  .partner-list li {
    width: 50%;
  }
}

@media (max-width: 840px) {
  .partner-list-page {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .partner-list-page-header {
    margin-bottom: 20px;
  }

  .partner-list-page-header h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .partner-list-page-header p {
    font-size: 18px;
  }
}
