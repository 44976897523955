.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  height: 50px;
  padding: 0 20px;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #343434;
}

.button.outline {
  color: var(--color-secundary);
  border-color: var(--color-secundary);
  background-color: transparent;
}

.button.full {
  width: 100%;
}

.button:disabled {
  cursor: not-allowed !important;
}

