.nav ul {
  display: flex;
}

.nav ul li {
  margin-right: 7.5px;
}

.nav ul li:last-child {
  margin-right: 0;
}

.nav ul li a {
  display: block;
  padding: 15px 20px;
  font-size: 20px;
  color: #343434;
}

.nav ul li a span {
  position: relative;
  display: block;
  padding-bottom: 10px;
}

.nav ul li a span::after {
  opacity: 0;
  position: absolute;
  left: calc(50% - 10px);
  bottom: 0;
  width: 20px;
  height: 5px;
  border-radius: 3px;
  background-color: #343434;
  content: '';
}

.nav ul li a.active span::after,
.nav ul li a:hover span::after {
  opacity: 1;
}

@media (max-width: 1020px) {
  .nav ul {
    width: 100%;
    flex-direction: column;
  }

  .nav ul li a {
    padding: 5px 0;
    display: block;
    text-align: center;
  }

  .nav ul li a span {
    padding-bottom: 0;
  }

  .nav ul li a span::after {
    display: none;
  }

  .nav ul li a.active {
    font-weight: 600;
  }
}
