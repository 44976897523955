/* section main */

.section-main {
  background-color: #52543f;
  text-align: center;
}

/* section-clube */

.section-clube {
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: var(--color-secundary);
  color: #fff;
}

.section-clube > .container {
  position: relative;
}

.section-clube-header {
  margin-bottom: 78px;
  font-size: 42px;
  font-weight: 700;
  color: #343434;
}

.section-clube-header span {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 10px 58px 10px 0;
}

.section-clube-header span::before {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -400%;
  right: 0;
  border-radius: 0 50px 50px 0;
  background-color: var(--color-primary);
  content: '';
}

.section-clube-columns {
  display: flex;
  font-size: 24px;
}

.section-clube-column-left {
  width: 45%;
}

.section-clube-column-right {
  width: 55%;
  padding-left: 85px;
}

@media (max-width: 840px) {
  .section-clube {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-clube-header {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .section-clube-header span {
    padding: 10px 18px 10px 0;
  }

  .section-clube-columns {
    flex-wrap: wrap;
    font-size: 18px;
  }

  .section-clube-column-left {
    width: 100%;
  }

  .section-clube-column-right {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
}

/* section-partner */

.section-partner {
  padding-top: 200px;
  padding-bottom: 65px;
  background-color: #f2f2f2;
  background-image: url('../../assets/images/partner-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom 50% right -20%;
  color: var(--color-text-1);
}

.section-partner-info h1 {
  margin-bottom: 42px;
  font-size: 50px;
  font-weight: 700;
  color: var(--color-text-2);
}

.section-partner-info p {
  max-width: 955px;
  font-size: 20px;
  line-height: 1.9;
}

@media (max-width: 840px) {
  .section-partner {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-partner-info h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .section-partner-info p {
    max-width: 100%;
    font-size: 18px;
  }

  .section-partner-action {
    margin-top: 40px;
  }

  .section-partner-action a {
    min-width: 100%;
    width: 100%;
  }
}

/* benefits */

.benefits {
  display: flex;
  margin-top: 60px;
  margin-bottom: 110px;
}

.benefits li {
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.benefits li:first-child {
  padding-left: 0;
}

.benefits li:last-child {
  padding-right: 0;
}

.benefits li span {
  display: block;
  margin-top: 25px;
  font-size: 20px;
}

.benefits-image {
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 980px) {
  .benefits {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .benefits li {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 520px) {
  .benefits li {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .benefits-image {
    height: auto;
  }
}

/* section-app */

.section-app {
  background-color: #f2f2f2;
  color: var(--color-text-1);
}

.section-app-container {
  display: flex;
  padding-top: 70px;
}

.section-app-info {
  width: 50%;
}

.section-app-info h1 {
  margin-bottom: 42px;
  line-height: 1.4;
  font-size: 50px;
  font-weight: 700;
  color: var(--color-text-2);
}

.section-app-info p {
  max-width: 760px;
  font-size: 20px;
  line-height: 1.9;
}

.section-app-stores {
  margin-top: 112px;
  margin-bottom: 132px;
}

.section-app-image {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  overflow: hidden;
  padding-left: 20px;
  background-color: #f2f2f2;
}

.section-app-image img {
  position: relative;
  margin-bottom: -303px;
  margin-left: 12%;
}

@media (max-width: 840px) {
  .section-app-container {
    padding-top: 20px;
  }

  .section-app-container {
    display: block;
  }

  .section-app-info {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .section-app-info h1 {
    margin-bottom: 20px;
    font-size: 30px;
  }

  .section-app-image {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
    text-align: center;
  }

  .section-app-image img {
    margin-left: 0;
  }

  .section-app-stores {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
}
