.upload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  border: 1px dashed #b8b8c9;
  height: var(--height);
  cursor: pointer;
}

.upload.with-info {
  border-radius: 10px;
  background-color: transparent;
  border: 1px dashed #b8b8c9;
}

.upload img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.upload input {
  display: none !important;
}

.upload-icon {
  font-size: 17px;
  color: #a3a3a3;
  text-align: center;
}

.upload-icon img {
  margin-bottom: 10px;
}

.upload-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-info-text {
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #b8b8c9;
  text-align: center;
}

.upload-info-text p {
  margin-top: 5px;
}

@media (max-width: 480px) {
  .upload-info {
    padding: 10px;
    flex-direction: column;
  }

  .upload-info-text {
    padding-left: 0;
    margin-top: 10px;
    font-size: 12px;
  }
}
