.terms-content {
  position: relative;
  padding: 40px;
}

.terms-content h1 {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.terms-content h2 {
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 700;
}

.terms-content p {
  margin-bottom: 25px;
  text-align: justify;
}

.terms-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

@media (max-width: 840px) {
  .terms-content {
    padding: 20px;
  }

  .terms-container {
    padding-top: 20px;
  }

  .terms-close {
    top: 10px;
    right: 10px;
    width: 30px;
  }

  .terms-content h1,
  .terms-content h2,
  .terms-content p {
    margin-bottom: 20px;
  }
}
