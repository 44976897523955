.adm-nav {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 984px;
  margin-left: auto;
  margin-right: auto;
}

.adm-nav li {
  height: 105px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
}

.adm-nav li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.adm-nav li a.active-menu {
  border-style: solid;
  border-color: var(--color-primary);
  border-radius: 10px;
}

.adm-nav li a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
}

.adm-nav li a span {
  margin-left: 24px;
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text-2);
}

@media (max-width: 840px) {
  .adm-nav {
    gap: 20px;
  }

  .adm-nav li {
    height: auto;
  }

  .adm-nav li a {
    padding: 10px;
  }

  .adm-nav li a i {
    display: none;
  }

  .adm-nav li a span {
    margin-left: 0;
    font-size: 18px;
  }
}

@media (max-width: 420px) {
  .adm-nav {
    gap: 10px;
  }

  .adm-nav li a span {
    font-size: 16px;
  }
}
