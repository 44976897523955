.select {
  position: relative;
  height: 68px;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  color: var(--color-text-2);
}

.select label {
  position: absolute;
  top: 7px;
  left: 20px;
  font-size: 14px;
  color: inherit;
  cursor: text;
}

.select label + select {
  padding-top: 30px !important;
}

.select select {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  font-size: 20px;
  color: inherit;
  padding: 8px 16px;
}

.select select:focus {
  outline: none;
}
