.select-multiple {
  border: 1px solid var(--color-border);
  border-radius: 3px;
}

.select-multiple-info {
  position: relative;
  cursor: pointer;
  height: 68px;
  padding-top: 7px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  color: var(--color-text-2);
}

.select-multiple-info span {
  font-size: 14px;
}

.select-multiple-info p {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: -3px;
  font-size: 20px;
  color: #a6a6a6;
}

.select-multiple-info p.active {
  color: var(--color-text-2);
}

.select-multiple-message {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid var(--color-border);
  font-size: 20px;
  color: #a6a6a6;
  text-align: center;
}

.select-multiple-items {
  max-height: 105px;
  overflow-y: scroll;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid var(--color-border);
}

.select-multiple-items::-webkit-scrollbar {
  width: 4px;
}

.select-multiple-items::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #e0e0e0;
}

.select-multiple-items li {
  cursor: pointer;
  padding: 2px 0;
  font-size: 20px;
  color: #a6a6a6;
}

.select-multiple-items li:first-child {
  padding-top: 5px;
}

.select-multiple-items li:last-child {
  padding-bottom: 5px;
}

.select-multiple-items li.active {
  font-weight: 500;
  color: var(--color-primary);
}
