.header {
  position: relative;
  height: 90px;
  color: #fff;
  background-color: var(--color-primary);
}

.header > .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo {
  flex: 0 0 auto;
  margin-right: 95px;
}

.header-logo.center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-menu-button {
  display: none;
}

.header-actions {
  display: flex;
}

.header-actions a:first-child {
  margin-right: 26px;
}

.header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secundary);
  width: 186px;
  height: 39px;
  border-radius: 5px;
  border: 2px solid var(--color-secundary);
  font-size: 20px;
  color: #fff;
}

.header-button.outline {
  background-color: transparent;
  color: #343434;
}

.header-authorized {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-authorized-user {
  display: flex;
  align-items: center;
}

.header-authorized-menu {
  margin-right: 40px;
  display: flex;
}

.header-authorized-menu li a,
.header-authorized li button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  padding: 0 20px;
  font-size: 20px;
  color: #343434;
}

.header-authorized li button {
  font-weight: 600;
}

.header-authorized-menu li a span {
  position: relative;
  display: block;
  padding-bottom: 10px;
}

.header-authorized-menu li a span::after{
  opacity: 0;
  position: absolute;
  left: calc(50% - 10px);
  bottom: 0;
  width: 20px;
  height: 5px;
  border-radius: 3px;
  background-color: #343434;
  content: '';
}

.header-authorized-menu li a.active span::after,
.header-authorized-menu li a:hover span::after {
  opacity: 1;
}

@media (max-width: 1020px) {
  .header-logo {
    margin-right: 0;
  }

  .header-right {
    position: absolute;
    display: none;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 90px;
    padding: 10px;
    flex-direction: column;
    background-color: var(--color-primary);
  }

  .header-right.visible {
    display: block;
  }

  .header-menu {
    margin-bottom: 10px;
  }

  .header-menu {
    width: 100%;
  }

  .header-menu-button {
    --size: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - var(--size) / 2);
    right: 20px;
    width: calc(var(--size) + 10px);
    height: var(--size);
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    transform: rotate(180deg);
  }

  .header-menu-button img {
    width: 45px;
  }

  .header-menu-button.active {
    transform: rotate(0deg);
  }

  .header-actions {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .header-actions a {
    width: inherit;
  }

  .header-actions a:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 840px) {
  .header-authorized {
    margin-bottom: 10px;
  }

  .header-authorized-menu li a {
    height: auto;
  }
}
