.address-form-container {
  padding: 40px 50px;
}

.address-form-header {
  padding-bottom: 20px;
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 600;
  border-bottom: 2px solid var(--color-border);
}

.address-form-input {
  margin-bottom: 30px;
}

.address-form-columns {
  display: flex;
  gap: 30px;
}

.address-form-column-left {
  flex: 1;
}

.address-form-column-right {
  flex: 0 0 250px;
}

.address-form-column-50 {
  width: 50%;
}

.address-form-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.address-form-radio-group li {
  margin-right: 40px;
}

.address-form-radio-group li:last-child {
  margin-right: 0;
}

.address-form-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.address-form-actions button {
  margin-left: 15px;
  margin-right: 15px;
}

/* places-autocomplete */

.places-autocomplete-container {
  position: relative;
}

.places-autocomplete-list {
  position: absolute;
  z-index: 1;
  top: -1px;
  width: 100%;
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-top: 0;
}

.places-autocomplete-list li {
  cursor: pointer;
  padding: 2px 20px;
  font-size: 20px;
  color: #a6a6a6;
}

.places-autocomplete-list li:hover {
   background-color: rgba(0, 0, 0, 0.04);
}

@media (max-width: 840px) {
  .address-form-container {
    padding: 20px;
  }

  .address-form-header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 22px;
  }

  .address-form-columns {
    flex-direction: column;
    gap: 20px;
  }

  .address-form-column-50 {
    width: 100%;
  }

  .address-form-input {
    margin-bottom: 20px;
  }

  .address-form-column-right {
    flex: 1;
  }
}

@media (max-width: 620px) {
  .address-form-radio-group li {
    margin-right: 10px;
  }

  .address-form-actions {
    margin-top: 20px;
  }

  .address-form-actions {
    flex-direction: column;
  }

  .address-form-actions button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .address-form-actions button:first-child {
    order: 1;
    margin-top: 10px;
  }
}
