.user {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  background-color: var(--color-secundary);
}

.user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 840px) {
  .user {
    width: 44px !important;
    margin-bottom: 0 !important;
  }
}
